
import { defineComponent, reactive, toRefs, ref, computed } from 'vue'
import detailLayout from '@/components/detailLayout/index.vue'
import FormItem from '@/components/formItem/index.vue'
import Data from '@/model/common/Data'
import { message } from 'ant-design-vue'
import demandApi from '@/axios/api/demandApi'
import { useRouter } from 'vue-router'
import store from '@/store/index'
interface FileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  preview?: string
  size: number
  file: string | Blob | File | FileItem
}
export default defineComponent({
  components: {
    FormItem,
    // CloseOutlined,
    // InfoCircleOutlined,
    // customerSelect,
    detailLayout,
  },
  setup() {
    const formRef = ref()
    const router = useRouter()
    let checkPhone = async (rule: string, value: string) => {
      if (!value) {
        return Promise.reject('请输入手机号')
      }
      if (!/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(value)) {
        return Promise.reject('手机号格式不正确')
      } else {
        return Promise.resolve()
      }
    }
    const pageData = reactive({
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      demandForm: {
        companyName: '', //公司名称
        contact: '', //联系人
        contactPhone: '', //联系人手机号
        demandProduct: '', //需求产品名称
        demandCount: '', //预计需求数量
        useEnv: '', //使用环境
        demandDate: '', //需求时间
        demandDesc: '', //需求描述
        fileIdList: '', //文件集合
      },
      //表单数据
      rules: {
        companyName: [{ required: true, message: '公司名称不可为空', trigger: 'change' }],
        contact: [{ required: true, message: '联系人不可为空', trigger: 'change' }],
        contactPhone: [{ required: true, validator: checkPhone, trigger: 'change' }],
        demandProduct: [{ required: true, message: '需求产品不可为空', trigger: 'change' }],
        // deviceName: [{ required: true, message: '设备名称不可为空', trigger: 'blur' }],
        demandCount: [{ required: true, message: '预计需求数量不可为空', trigger: 'change' }],
        useEnv: [{ required: true, message: '使用环境不可为空', trigger: 'change' }],
        demandDate: [{ required: true, message: '需求时间不可为空', trigger: 'change' }],
      },
      formItemList: [
        {
          type: 'subInput',
          prop: 'companyName',
          placeholder: '请输入公司名称',
          label: '公司名称',
          maxlength: 255,
        },
        {
          type: 'subInput',
          prop: 'contact',
          placeholder: '请输入联系人',
          label: '联系人',
          maxlength: 32,
        },
        {
          type: 'subInput',
          prop: 'contactPhone',
          placeholder: '请输入联系人手机号',
          label: '联系人手机号',
        },
        {
          type: 'subInput',
          prop: 'demandProduct',
          placeholder: '请输入需求产品名称',
          label: '需求产品名称',
          maxlength: 128,
        },
        {
          type: 'subInput',
          prop: 'deviceName',
          placeholder: '请输入应用设备名称',
          label: '应用设备名称',
          maxlength: 128,
        },
        {
          type: 'subInput',
          prop: 'demandCount',
          placeholder: '请输入预计需求数量',
          label: '预计需求数量',
          inputType: 'number',
        },
        {
          type: 'subDate',
          prop: 'demandDate',
          placeholder: '请选择需求时间',
          label: '需求时间',
          valueFormat: 'YYYY-MM-DD 00:00:00',
        },
        {
          type: 'subInput',
          prop: 'useEnv',
          placeholder: '请输入应用场景和功能',
          label: '应用场景和功能',
          maxlength: 255,
        },
        {
          type: 'textarea',
          prop: 'demandDesc',
          placeholder: '请输入需求描述',
          label: '描述(现有问题和痛点)',
          maxlength: 500,
        },
      ],
      fileList: [] as FileItem[],
      progressList: [] as Data[],
      suspendedText: '暂停',
    })
    function change(limit: number) {
      var size = ''
      if (limit < 0.1 * 1024) {
        //小于0.1KB，则转化成B
        size = limit.toFixed(2) + 'B'
      } else if (limit < 0.1 * 1024 * 1024) {
        //小于0.1MB，则转化成KB
        size = (limit / 1024).toFixed(2) + 'KB'
      } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        //小于0.1GB，则转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
      } else {
        //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
      }

      var sizeStr = size + '' //转成字符串
      var index = sizeStr.indexOf('.') //获取小数点处的索引
      var dou = sizeStr.substr(index + 1, 2) //获取小数点后两位的值
      if (dou == '00') {
        //判断后两位是否为00，如果是则删除00
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
      }
      return size
    }
    const size = computed(() => {
      let limit = 0
      pageData.progressList.forEach((item) => {
        if (item.status === 'success') {
          limit += item.size as number
        }
      })
      return change(limit)
    })
    const customRequest = (param: FileItem) => {
      console.log('上传', param)
      if ((param.file as FileItem).size / 1024 / 1024 > 10) {
        message.error('单个上传文件不得大于10M！')
        return
      }
      const formData = new FormData()
      let progress = reactive({
        uid: (param.file as FileItem).uid,
        name: (param.file as File).name,
        percent: 0,
        status: '',
        suspended: 0,
        suspendedStatus: '',
        fileID: '',
        size: (param.file as FileItem).size as number,
      })
      pageData.progressList.push(progress)
      interface DataNumber {
        [key: string]: number
      }
      const config = {
        onUploadProgress: (uploadProgress: DataNumber) => {
          // 格式化成百分数
          progress.percent = Math.floor((uploadProgress.loaded / uploadProgress.total) * 100)
        },
      }
      formData.append('file', param.file as Blob)
      demandApi
        .uploadFile(Object.assign(formData, { noLoading: true }), config)
        .then((res) => {
          if (!progress.suspended) {
            progress.status = 'success'
          }
          progress.fileID = res
          console.log(pageData.fileList)
        })
        .catch((err) => {
          progress.status = 'exception'
          console.log(pageData.progressList)
          message.error(err || '上传失败')
        })
    }
    const deleteFile = (uid: string) => {
      let index = pageData.fileList.findIndex((item) => item.uid === uid)
      let proIndex = pageData.progressList.findIndex((item) => item.uid === uid)
      pageData.fileList.splice(index, 1)
      pageData.progressList.splice(proIndex, 1)
    }
    //提交
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          // pageData.demandForm.companyName = '惠州市联赢科技有限公司'
          // if (pageData.demandForm.demandDate.length <= 10 && pageData.demandForm.demandDate)
          //   pageData.demandForm.demandDate = pageData.demandForm.demandDate + ' 00:00:00'

          let data = pageData.progressList.filter(
            (item) => item.status === 'success' && item.fileID
          )
          pageData.demandForm.fileIdList = data.map((item) => item.fileID).join(',')
          // pageData.progressList.forEach((item) => {
          //   if (item.status === 'success' && item.fileID) {
          //     pageData.demandForm.fileIdList.push(item.fileID as string)
          //   }
          // })
          demandApi
            .ProposalCustomerAdd(pageData.demandForm)
            .then(() => {
              router.push(`/requirementsGathering/success`)
            })
            .catch((err) => {
              message.error(err)
              if (err === '当前用户未认证') {
                store.commit('setDemandForm', pageData.demandForm)
                router.push({
                  path: '/requirementsGathering/authenticationInformation',
                  query: {
                    phone: pageData.demandForm.contactPhone,
                  },
                })
              }
            })
        })
        .catch(() => {
          console.log('验证不通过')
        })
    }
    const suspended = (uid: string, status: string) => {
      if (status === '暂停') {
        pageData.progressList.forEach((item) => {
          if (item.uid === uid) {
            item.suspended = item.percent
            item.status = 'active'
          }
        })
        pageData.suspendedText = '继续'
      } else {
        pageData.progressList.forEach((item) => {
          if (item.uid === uid) {
            item.suspended = 0
            item.status = ''
          }
        })
        pageData.suspendedText = '暂停'
      }
    }
    const reset = () => {
      formRef.value.resetFields()
    }
    return {
      ...toRefs(pageData),
      customRequest,
      deleteFile,
      formRef,
      onSubmit,
      suspended,
      size,
      reset,
    }
  },
})
